.quickActionPanel {
  width: 100dvw;
  /* display: flex;
  flex-wrap: nowrap; */
  display: -webkit-box;
  gap: 1em;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(252, 88, 88, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.quickActionButton:hover {
  transition: 0.3s ease-in-out;
  transform: translate(-3px, -3px);
  box-shadow: rgba(0, 0, 0, 0.345) 2.4px 2.4px 3.2px;

}

.quickActionButton {
  display: inline;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: none;
  border: .5px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickActionButtonText {
  width: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}

.quickActionButtonImg {

}