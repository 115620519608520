.shared_container {
    display: flex;
    height: 79dvh;
    white-space: nowrap;
    margin-bottom: 10px;
}

.container {
    display: block;
    overflow-y: scroll;;
}

.container:not(:last-child) {
    margin-right: 1rem;
}

data-grid {
    overflow-y: scroll;
}

data-list {
    overflow-y: auto;
}

.MuiDataGrid-cell {
    min-height: 1.3rem !important;
}

@media (max-width: 768px) {
    .shared_container{
        margin-right: 0;
        padding-bottom: 10px;
    }
    .shared_container {
        flex-direction: column;
    }
    .data-list {
        height: 40%;
        border: 1px solid rgba(224, 224, 224, 1);
    }
    .MuiListItemButton-root {
        padding: 3px !important;
    }

    .data-list span {
        font-size: .8rem;
    }
    .MuiDataGrid-cell {
        font-size: .8rem;
        min-height: 1.7rem !important;

    }
    .MuiDataGrid-columnHeaders{
        height: 30px !important;
    }
    .MuiDataGrid-columnHeader{
        height: 30px !important;
    }
    .container:not(:last-child) {
        margin-right: 0;
    }
    .MuiDataGrid-columnHeaderTitle {
        font-size: .8rem;
    }
}