.contexify .contexify_submenu {
    /* max-height: 10rem; */
    padding: 3px;
}

.contexify {
    padding: 3px;
}
.submenuBox {
    max-height: calc(10rem - 12px);
}

@media screen and (max-width: 780px) {
    .contexify .contexify_submenu {
        top: 20px;
        left: 20px !important;
        bottom: auto;
        right: auto !important;
        /* max-height: 10rem; */
    }
    .contexify_itemContent{
        font-size: 0.7rem !important;
    }
    .contexify_submenu {
        left: max(auto, 20px);
        right: max(auto, 20px);
    } 
}